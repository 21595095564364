// src/App.js
import React from "react";

import LandingPage from "./components/modules/landingPage";

const App = () => {
  return <LandingPage />;
};

export default App;
