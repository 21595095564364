import React from "react";
import bgPage from "../../assets/bg.png";
import InstagramEmbed from "./InstagramEmbed";
import FloatingWhatsAppButton from "./FloatingWhatsAppButton";
import info from "../../assets/cashless.jpg";
import logo from "../../assets/labbaik.png";

const LandingPage = () => {
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${bgPage})`,
        backgroundAttachment: "fixed",
      }}>
      <div className="max-w-6xl w-full grid grid-cols-1 md:grid-cols-3 gap-4 ">
        <div className="md:col-span-3 flex justify-center items-center mt-20 md:mt-0">
          <img
            src={logo}
            alt="Logo Labbaik"
            className=" min-w-40 max-w-60 h-auto"
          />
        </div>
        <div className=" flex flex-col justify-center items-center text-center">
          <h2 className="text-4xl font-bold mb-4">
            Right now we are cooking up a miracle.
          </h2>
          <p className="text-lg mb-8">Come back very soon.</p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            <a
              href="https://forms.gle/3Zn3EHuM5kaeuJFdA"
              target="_blank"
              rel="noopener noreferrer">
              Lowongan Kerja
            </a>
          </button>
        </div>
        <div className="flex justify-center items-center md:mt-12 p-6">
          <img
            src={info}
            alt="Informasi Cashless"
            className="max-w-full h-auto"
          />
        </div>
        <div className="flex justify-center items-center p-6 mb-20 md:mb-0">
          <InstagramEmbed />
        </div>
        <FloatingWhatsAppButton />
      </div>
      {/* <footer className="w-full bg-gray-800 text-white p-4 text-center fixed bottom-0">
        &copy; 2024 Labbaik Chicken. All rights reserved.
      </footer> */}
    </div>
  );
};

export default LandingPage;
