// src/components/FloatingWhatsAppButton.jsx
import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const FloatingWhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/6285220000800"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg flex items-center justify-center transition duration-300">
      <FaWhatsapp size={24} />
      Hubungi Kami
    </a>
  );
};

export default FloatingWhatsAppButton;
